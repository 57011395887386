import React from "react";

const Hero = () => {
  return (
    <>
      <section className="text-white text-center">
        <div id="hero" className="flex flex-wrap content-center justify-center">
          <div>
            <h1 className="mb-0 text-xl">Hello, my name is</h1>
            <h2 className="mb-1 text-7xl">Rashaad Randall.</h2>
            <h3 className="text-gray-400 text-xl">I build scalable web applications.</h3>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
